import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.css';
// import logo from "../../Images/darlogo2.png";
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Contactpopup from './Contact-popup'
import $ from 'jquery';
import host from "./../../config/api";
import menu from "../../Images/menu.svg";
import menu2 from "../../Images/menu-2.svg";
import menu3 from "../../Images/menu-3.svg";
import menu4 from "../../Images/menu-4.svg";
import menu5 from "../../Images/menu-5.svg";
import menu6 from "../../Images/menu-6.svg";
import menu7 from "../../Images/menu-7.svg";

export class HeaderMobile extends Component {
    constructor(props) {
        super(props);
        this.project = {};
        
      }
      componentDidMount() {
          
        this.project = this.props.project;
        
      } 
      
    render() {
        $(document).ready(function () {
      $("img.menu").off("click").on("click", function () {
            if ($("#Header.mobile ul.ml-auto").hasClass("ishidden")) {
                $("#Header.mobile ul.ml-auto").removeClass("ishidden").removeClass("rotate-reset").addClass("rotate-active");
                return;
            } else {
                $("#Header.mobile ul.ml-auto").addClass("ishidden").removeClass("rotate-active").addClass("rotate-reset");
             return;
            }
      });
        });
        if (this.props.project) {
        return (
            <div>
                <nav id="Header" className="navbar navbar-expand-lg home-header lp mobile">
                    <ul className="navbar-nav" style={{right: "8px", position: "absolute"}}>
                        <li className="nav-item info" style={{color: "#000"}}>
                            <FontAwesomeIcon icon={faPhoneAlt} style={{rotate: "120deg", width: "18px", height: "18px", padding: "4px"}}/>
                            <a href="tel:+212522211717" style={{fontSize: "8px", color: "#000"}}>+212 522 21 17
                                17</a> / <a style={{fontSize: "8px", color: "#000"}} href="tel:+212614989898">+212
                            614 98 98 98</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto lp ishidden">
                        <li className="item">
                            <Link className="link" to="/">ACCUEIL</Link>
                        </li>
                        <li className="item">
                            <Link className="link" to="/apropos">A PROPOS</Link>
                        </li>
                        <li className="item dropdown">
                            <Link className="link dropdown-toggle" data-toggle="dropdown" role="button"
                                  aria-haspopup="true" aria-expanded="false">NOS PROJETS</Link>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="/Project/AnfaBloom">Anfa Bloom</a>
                                <a className="dropdown-item" href="/Project/16eme-angle">Le 16 eme Angle</a>
                                <a className="dropdown-item" href="/Project/the-sand-house">The Sand House</a>
                                <a className="dropdown-item" href="/Project/blue-waters-house">Blue Waters House</a>
                                <a className="dropdown-item" href="/Project/magic-house-beach">Magic House Beach</a>
                                <a className="dropdown-item" href="/Project/les-perles-de-bentriaa">Les Perles de
                                    Bentriaa II</a>
                                <a className="dropdown-item" href="/Project/magic-house">Magic House</a>
                                <a className="dropdown-item" href="/Project/garden-bay">Garden Bay</a>
                                <a className="dropdown-item" href="/Project/ricoflores-palm">Ricoflores Palm</a>
                            </div>
                        </li>
                        <li className="link social">
                            <a className="social-media" target="_blank"
                               href="https://fr-fr.facebook.com/Chaabaneimmobilier/"><FontAwesomeIcon
                                icon={faFacebook}/></a>
                            <a className="social-media" target="_blank"
                               href="https://ma.linkedin.com/in/chaabane-immobilier-196523120"><FontAwesomeIcon
                                icon={faLinkedin}/></a>
                            <a className="social-media" target="_blank"
                               href="https://www.instagram.com/chaabane.immobilier/?hl=fr"><FontAwesomeIcon
                                icon={faInstagram}/></a>
                        </li>
                    </ul>
                    <img src={menu} className="d-block menu m1" alt="..." style={{display: "none"}}/>
                    <img src={menu2} className="d-block menu m2" alt="..." style={{display: "none"}}/>
                    <img src={menu3} className="d-block menu m3" alt="..." style={{display: "none"}}/>
                    <img src={menu4} className="d-block menu m4" alt="..." style={{display: "none"}}/>
                    <img src={menu5} className="d-block menu m5" alt="..." style={{display: "none"}}/>
                    <img src={menu6} className="d-block menu m6" alt="..." style={{display: "none"}}/>
                    <img src={menu7} className="d-block menu m7" alt="..." style={{display: "none"}}/>
                </nav>
                <nav id="Header2" className="navbar navbar-expand-lg">
                    <img
                        src={host + "uploads/" + this.props.project.logoP}
                        className="d-block"
                        alt="..."
                    />
                    <ul className="navbar-nav ml-auto ">
                        <li className="item">
                            <span className="contact-popup-btn">NOUS CONTACTER</span>
                        </li>
                    </ul>
                </nav>
                <div className="contact-popup hidden">
                    <Contactpopup/>
                </div>
            </div>

        )
        } else {
            return <div></div>;
        }
    }
}

export default HeaderMobile
