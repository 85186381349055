import React, { Component } from "react";
import "./index.css";
import HeaderHome from "../websitelayout/Header-home";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";
import image from "../../Images/carriere.png";
import {
  faMapMarkerAlt,
  faFax,
  faPhoneAlt,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import Noprojet from "../projet/nosprojet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import MediaQuery from "react-responsive";
import EmailService from '../../services/email-service';
import UploadService from '../../services/uploadfile-service';
// import { useNavigate } from 'react-router-dom';
import checkedImage from '../../Images/checkedFileUploaded.png';
import host from "../../config/api";

export class index extends Component {
  constructor(props) {
    super(props);
    this.emailService = new EmailService();
    this.uploadService = new UploadService();
    this.state = { 
      name: '',
      email: '',
      phone: '', 
      sujet: '',
      selectedcv: null,
      selectedMotivation: null,
      cvdfileName: '',
      motivationfileName: '',
      messageError: '',
      cvUploded: false,
      motivationUploded: false
  };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleSujet = this.handleSujet.bind(this);
    this.handleCV = this.handleCV.bind(this);
    this.handleMotivation = this.handleMotivation.bind(this);

  }

  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("home-page carriere");
    $(".progress.mx-auto.cv,.progress.mx-auto.lettre").hide();
    $(document).ready(function () {
      $(".btn").click(function () {
        var fd = new FormData();
        var files = $('#cv')[0].files[0];
        fd.append('file', files);

        // $.ajax({ 
        //     url: 'upload.php', 
        //     type: 'post', 
        //     data: fd, 
        //     contentType: false, 
        //     processData: false, 
        //     success: function(response){ 
        //         if(response != 0){ 
        //            alert('file uploaded'); 
        //         } 
        //         else{ 
        //             alert('file not uploaded'); 
        //         } 
        //     }, 
        // }); 
      });
    });

    $(function () {
      $(".progress").each(function () {
        var value = $(this).attr("data-value");
        var left = $(this).find(".progress-left .progress-bar");
        var right = $(this).find(".progress-right .progress-bar");

        if (value > 0) {
          if (value <= 50) {
            right.css(
              "transform",
              "rotate(" + percentageToDegrees(value) + "deg)"
            );
          } else {
            right.css("transform", "rotate(180deg)");
            left.css(
              "transform",
              "rotate(" + percentageToDegrees(value - 50) + "deg)"
            );
          }
        }
      });

      function percentageToDegrees(percentage) {
        return (percentage / 100) * 360;
      }
    });
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }
  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }
  handleSujet(event) {
    this.setState({ sujet: event.target.value });
  }
  async handleCV(event) {
    console.log(event.target.files[0]);
   // this.setState({ selectedcv: event.target.files[0], loaded: 0, });
   if (!event.target.files[0].name.match(/\.(gif|jpg|jpeg|tiff|png|pdf|doc|docx|txt)$/i)) {
    this.setState({ messageError: "Les fichiers acceptable sont 'gif' 'jpg' 'jpeg' 'tiff' 'png' 'pdf' 'doc' 'docx' 'txt'" });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
   }
    const data = new FormData();
    data.append('uploadFile', event.target.files[0]);
   await this.uploadService.upload(data).then(res => {
       console.log('fileName', res.data.name);
       this.setState({ cvdfileName: res.data.name });
       this.setState({ cvUploded: true });
    });
    
  }
  async handleMotivation(event) {
    console.log(event.target.files[0]);
    //this.setState({ selectedMotivation: event.target.files[0], loaded: 0 });
    if (!event.target.files[0].name.match(/\.(gif|jpg|jpeg|tiff|png|pdf|doc|docx|txt)$/i)) {
      this.setState({ messageError: "Les fichiers acceptable sont 'gif' 'jpg' 'jpeg' 'tiff' 'png' 'pdf' 'doc' 'docx' 'txt'" });
        setTimeout(() => {
          this.setState({ messageError: '' });
        }, 2000);
        return false;
     }
    const data = new FormData();
    //data.append('file', this.state.selectedMotivation);
    data.append('uploadFile', event.target.files[0]);
   await this.uploadService.upload(data).then(res => {
      console.log('fileName', res.data.name);
       this.setState({ motivationfileName: res.data.name});
       this.setState({ motivationUploded: true });
    });
    
  }
  async send(event) {
    event.preventDefault();
    if (!this.state.name || this.state.name === "") {
      this.setState({ messageError: 'Veuillez saisir votre nom' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!this.state.email || this.state.email === "") {
      this.setState({ messageError: 'Veuillez saisir votre email' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.setState({ messageError: 'Veuillez saisir une adresse email valide' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!this.state.phone || this.state.phone === "") {
      this.setState({ messageError: 'Veuillez saisir votre telephone' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!this.state.sujet || this.state.sujet === "") {
      this.setState({ messageError: 'Veuillez charger sujet' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!this.state.cvdfileName || this.state.cvdfileName === "") {
      this.setState({ messageError: 'Veuillez charger votre cv' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }
    if (!this.state.motivationfileName || this.state.motivationfileName === "") {
      this.setState({ messageError: 'Veuillez charger lettre de motivation' });
      setTimeout(() => {
        this.setState({ messageError: '' });
      }, 2000);
      return false;
    }

    const condidat = {};
    condidat.name = this.state.name;
    condidat.email = this.state.email;
    condidat.phone = this.state.phone;
    condidat.sujet = this.state.sujet;
    condidat.cv = host + 'uploads/' + this.state.cvdfileName;
    condidat.motivation = host + 'uploads/' + this.state.motivationfileName;

    console.log('condidature =>', condidat);

    this.emailService.sendCarriere(condidat).then(res => {
      console.log(res);
    });
    localStorage.setItem('condidat', true);
    this.props.history.push('/remerciements');
    
  }
  render() {
    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div
            id="carriere-section"
            className="row align-items-center"
            style={{ backgroundColor: "#fff" }}
          >
            <h1>CARRIÈRE</h1>
          </div>
          <div style={{ backgroundColor: "#fff" }}>
            <div className="row carriere-form">
              <div className="col-md-6 form">
                <p>
                  Chaabane Immobilier recrute régulièrement de nouveaux
                  collaborateurs pour ses équipes opérationnelles et
                  fonctionnelles.
              </p>
                <form>
                  <div className="form-group">
                    <input type="text" placeholder="Nom et Prénom" value={this.state.name}
                  onChange={this.handleName} />
                  </div>
                  <div className="form-group">
                    <input type="email" placeholder="Votre email" value={this.state.email}
                  onChange={this.handleEmail} />
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Téléphone" value={this.state.phone}
                  onChange={this.handlePhone}/>
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Sujet" value={this.state.sujet}
                  onChange={this.handleSujet} />
                  </div>
                  <div className="row">
                    <div
                      className="form-group col-md-6 col-6 cv"
                      style={{ textAlign: "center" }}
                    >
                      <label className="title-label">Charger un CV :</label>
                      {this.state.cvUploded ? (<div className='cv'><img src={checkedImage} className="icon-upload" alt='upload'/></div>):(<label className="cv" htmlFor="cv">
                        + 
                      </label>)}
                      <input type="file" id="cv" onChange={this.handleCV} />
                      {/* Progress bar  */}
                      <div className="progress mx-auto" data-value="50">
                        <span className="progress-left">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div className="h2 font-weight-bold">
                            80<sup className="small">%</sup>
                          </div>
                        </div>
                      </div>
                      {/* END */}
                    </div>
                    <div
                      className="form-group col-md-6 col-6 lettre"
                      style={{ textAlign: "center" }}
                    >
                      <label className="title-label"> Lettre de Motivation :</label>
                      {this.state.motivationUploded ? (<div className='letter'><img src={checkedImage} className="icon-upload" alt='upload'/></div>):(<label className="lettre" htmlFor="lettre">
                        + 
                      </label>)}
                      <input type="file" id="lettre" onChange={this.handleMotivation} />
                      {/* Progress bar  */}
                      <div className="progress mx-auto" data-value="50">
                        <span className="progress-left">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div className="h2 font-weight-bold">
                            80<sup className="small">%</sup>
                          </div>
                        </div>
                      </div>
                      {/* END */}
                    </div>
                  </div>
                  {this.state.messageError !== '' ?
                    (<div className="alert alert-danger">
                      <strong>{this.state.messageError}</strong>
                    </div>) : (<div></div>)
                  }
                  <button className="btn" disabled={!this.state.name || !this.state.email || !this.state.phone || !this.state.sujet || !this.state.motivationUploded || !this.state.cvUploded } onClick={this.send.bind(this)}>Envoyer</button>
                </form>
              </div>
              <div
                className="col-md-6 infos"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <h1>CONTACT</h1>
                <ul className="navbar-nav mr-auto">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <li className="nav-item info">
                    7 Lotissement Ghita Avenue « N » Californie Casablanca Maroc
                </li>
                  <FontAwesomeIcon style={{rotate: "120deg"}}  icon={faPhoneAlt} />
                  <li className="nav-item info">
                    Tél: <a href="tel:+212522211717">+212 522 21 17 17 </a>
                    <br /> Gsm: <a href="tel:+212614989898">+212 614 98 98 98</a>
                  </li>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <li className="nav-item info">
                    <a href="mailto:contact@chaabaneinvest.com">
                      contact@chaabaneinvest.com
                  </a>
                  </li>
                  <FontAwesomeIcon icon={faFax} />
                  <li className="nav-item info">
                    Fax: <a href="tel:05 22 50 03 10">05 22 50 03 10</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet />
          <HeaderHome />
          <div
            id="carriere-section"
            className="row align-items-center"
            style={{ backgroundColor: "#fff" }}
          >
            <h1>CARRIÈRE</h1>
          </div>
          <div style={{ backgroundColor: "#fff" }}>
            <div className="row carriere-form">
              <div className="col-md-6 form">
                <p>
                  Chaabane Immobilier recrute régulièrement de nouveaux
                  collaborateurs pour ses équipes opérationnelles et
                  fonctionnelles.
              </p>
                <form>
                  <div className="form-group">
                    <input type="text" placeholder="Nom et Prénom" value={this.state.name}
                  onChange={this.handleName} />
                  </div>
                  <div className="form-group">
                    <input type="email" placeholder="Votre email" value={this.state.email}
                  onChange={this.handleEmail} />
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Téléphone" value={this.state.phone}
                  onChange={this.handlePhone}/>
                  </div>
                  <div className="form-group">
                    <input type="text" placeholder="Sujet" value={this.state.sujet}
                  onChange={this.handleSujet} />
                  </div>
                  <div className="row">
                    <div
                      className="form-group col-md-6 col-6"
                      style={{ textAlign: "center" }}
                    >
                      <label className="title-label">Charger un CV :</label>
                    {this.state.cvUploded ? (<div className='cv'><img src={checkedImage}  className="icon-upload" alt='upload'/></div>):(<label className="cv" htmlFor="cv">
                        + 
                      </label>)}
                      <input type="file" id="cv" onChange={this.handleCV} />
                      {/* Progress bar  */}
                      <div className="progress mx-auto cv" data-value="50">
                        <span className="progress-left">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div className="h2 font-weight-bold">
                            80<sup className="small">%</sup>
                          </div>
                        </div>
                      </div>
                      {/* END */}
                    </div>
                    <div
                      className="form-group col-md-6 col-6"
                      style={{ textAlign: "center" }}
                    >
                      <label className="title-label"> Lettre de Motivation :</label>
                      {this.state.motivationUploded ? (<div className='letter'><img src={checkedImage} className="icon-upload" alt='upload'/></div>):(<label className="lettre" htmlFor="lettre">
                        + 
                      </label>)}
                      
                      <input type="file" id="lettre" onChange={this.handleMotivation}/>
                      {/* Progress bar  */}
                      <div className="progress mx-auto lettre" data-value="50">
                        <span className="progress-left">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <span className="progress-right">
                          <span className="progress-bar border-primary"></span>
                        </span>
                        <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                          <div className="h2 font-weight-bold">
                            80<sup className="small">%</sup>
                          </div>
                        </div>
                      </div>
                      {/* END */}
                    </div>
                  </div>
                  {this.state.messageError !== '' ?
                    (<div className="alert alert-danger">
                      <strong>{this.state.messageError}</strong>
                    </div>) : (<div></div>)
                  }
                  <button className="btn" disabled={!this.state.name || !this.state.email || !this.state.phone || !this.state.motivationUploded || !this.state.cvUploded } onClick={this.send.bind(this)}>Envoyer</button>
                </form>
              </div>
              <div
                className="col-md-6 infos"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <h1>CONTACT</h1>
                <ul className="navbar-nav mr-auto">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <li className="nav-item info">
                    7 Lotissement Ghita Avenue « N » Californie Casablanca Maroc
                </li>
                  <FontAwesomeIcon style={{rotate: "120deg"}}  icon={faPhoneAlt} />
                  <li className="nav-item info">
                    Tél: <a href="tel:+212522211717">+212 522 21 17 17 </a>
                    <br /> Gsm: <a href="tel:+212614989898">+212 614 98 98 98</a>
                  </li>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <li className="nav-item info">
                    <a href="mailto:contact@chaabaneinvest.com">
                      contact@chaabaneinvest.com
                  </a>
                  </li>
                  <FontAwesomeIcon icon={faFax} />
                  <li className="nav-item info">
                    Fax: <a href="tel:05 22 50 03 10">05 22 50 03 10</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </MediaQuery>


      </div>
    );
  }
}

export default index;
