import React, { Component } from "react";
// import Carousel from "bee-carousel";
// import image1 from "../../Images/image1.PNG";
// import image2 from "../../Images/image2.PNG";
// import image3 from "../../Images/image3.PNG";
// import image4 from "../../Images/image4.png";
import $ from "jquery";
import MediaQuery from "react-responsive";
import Noprojet from "../projet/nosprojet"
import HeaderHome from "../websitelayout/Header-home";
import ActualityService from '../../services/actuality-service';
import host from '../../config/api';
import { Link } from "react-router-dom";
import ActualityMobile from "../acceuil/actualityMobile";
import HeaderHomeMobile from "../websitelayout/Header-homeMobile";

export class index extends Component {
  constructor(props) {
    super(props);
    this.actualityService = new ActualityService();
    this.state = { actualities: [], status: false };
  }

  componentDidMount() {
    $("body").removeClass();
    $("body").addClass("actuality");
    $('#inputProjectFooter option[value=""]').prop('selected', true);
  }

  async componentDidMount() {

    await this.actualityService.getActualities().then(data => {
      this.setState({ actualities: data["data"].actualites, status: true });
      //localStorage.setItem
    });

  }
  getRoute(route) {
    return "/" + route;
  }
  render() {
    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderHomeMobile />
          <div className="mobile-actualites">
            <ActualityMobile />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Noprojet />
          <HeaderHome />

          <div
            className="home-page"
            style={{ backgroundColor: "white", padding: "80px 10px" }}
          >

            <h1
              style={{
                textAlign: "center",
                color: "#0d0c0b ",
                margin: "0 auto 50px auto",
                borderColor: "#b7853e"
              }}
            >
              NOS ACTUALITÉS
        </h1>
            <div className="container">
              <div className="row">

                {this.state.actualities ? this.state.actualities.map(actuality => (
                  <div className="article col-md-6 mb-4">
                    <img src={host + 'uploads/' + actuality.image} className="d-block w-100" alt={actuality.image} />
                    <div>
                      <p className="mt-4 mb-4">{actuality.title}</p>
                      <Link style={{ 
      display: "flex", 
      justifyContent: "center", 
      margin: "0 auto", 
      width: "fit-content" 
    }} className="btn" to={"/article/" + actuality.ref}>
                        Lire Plus
                 </Link>
                    </div>
                  </div>
                )) : (<div></div>)}
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default index;
